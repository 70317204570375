import { AgdirCompany, AuthService, getDecodedToken } from '@agdir/domain';
import { CUSTOMER_PATHS } from '@/constants';
import { inject, Injectable } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
class PermissionsService {
	private auth = inject(AuthService);
	private router = inject(Router);

	canActivate(): Observable<boolean> {
		return this.auth.getCurrentCustomerSession().pipe(
			switchMap((s) => {
				const token = getDecodedToken();
				if (token) {
					return of(!!(token && token.role?.includes(AgdirCompany.adminGroupName)));
				}
				return this.router.navigateByUrl(CUSTOMER_PATHS.AUTH_SIGNIN);
			}),
		);
	}
}

export const canActivatePowerUser: CanActivateFn | CanActivateChildFn = () => inject(PermissionsService).canActivate();
